<script lang="ts">
  import {
    CheckCircleSolid,
    ExclamationCircleSolid,
  } from "flowbite-svelte-icons";

  import { page } from "$app/stores";

  import Header from "./Header.svelte";
  import Sidebar from "./Sidebar.svelte";
  import { Toast } from "flowbite-svelte";

  import "../app.css";
  import {
    mounted,
    notificationStore,
    refreshToken,
    user,
    ressources,
    type Ressource,
    type INotificationStore,
    ressourceIdStore,
  } from "$lib/stores";
  import { onMount } from "svelte";
  import { goto } from "$app/navigation";
  import { type IResponse, fetchData } from "$lib/auth";
  import type { RessourceFull } from "$lib/types/entities/ressource.entity";
  import { redirect } from "@sveltejs/kit";

  let toasts: INotificationStore[] = [];

  notificationStore.subscribe((value) => {
    toasts = value;
  });

  function getTitle(pathname: string) {
    let baseName = "BBIA | ";

    // remove ressourceId from pathname (if it exists in 1st position)
    if (pathname.includes("/")) {
      const path = pathname.split("/");
      if (path.length > 2) {
        pathname = `/${path[2]}`;
      }
    }

    switch (pathname) {
      case "/":
        return baseName + "Home";
      case "/dashboard":
        return baseName + "Dashboard";
      case "/services":
        return baseName + "Services";
      case "/teams":
        return baseName + "Teams";
      case "/vendors":
        return baseName + "Vendors";
      case "/activities":
        return baseName + "Activities";
      case "/users":
        return baseName + "Users";
      case "/settings":
        return baseName + "Settings";
      case "/print":
        return baseName + "Print";
      case "/login":
        return baseName + "Login";
      case "/me":
        return baseName + "Profile";
      case "/about":
        return baseName + "About";
      case "/contact":
        return baseName + "Contact";
      case "/privacy":
        return baseName + "Privacy";
      case "/terms":
        return baseName + "Terms";
      case "/workspaces":
        return baseName + "Workspaces";
    }
    if (pathname.includes("/services/")) {
      return baseName + "Service";
    }
    if (pathname.includes("/teams/")) {
      return baseName + "Team";
    }
    if (pathname.includes("/vendors/")) {
      return baseName + "Vendor";
    }
    if (pathname.includes("/activities/")) {
      return baseName + "Activity";
    }
    if (pathname.includes("/users/")) {
      return baseName + "User";
    }
  }

  let ressourceIndex = 0;

  $: ressourceSelectedId = $ressourceIdStore;

  $: title = getTitle($page.url.pathname);

  $: printer = $page.url.pathname.includes("print");

  onMount(async () => {
    refreshToken.set(localStorage.getItem("refreshToken") || "");
    user.set(JSON.parse(localStorage.getItem("user") || "{}"));
    ressources.set(JSON.parse(localStorage.getItem("ressources") || "[]"));
    mounted.set(true);
    // listen for Crtl + / (search shortcut)
    window.addEventListener("keydown", (e) => {
      // windows and linux
      if (e.ctrlKey && e.key === "k") {
        e.preventDefault();
        const search = document.getElementById("search");
        if (search) search.focus();
      }
      // mac
      if (e.metaKey && e.key === "k") {
        e.preventDefault();
        const search = document.getElementById("search");
        if (search) search.focus();
      }
    });

    if ($ressources && $ressources.length === 0) {
      const data: IResponse | void = await fetchData("iamTeams", "full", fetch);
      if (!data) {
        return { error: "An error occurred", data: null };
      }
      if (data.status === 401) {
        redirect(302, "/logout");
      }
      if (!data.json) return { error: "No data", data: null };
      ressources.set(data.json as RessourceFull[]);
      ressourceIdStore.set($ressources[0].id);
      localStorage.setItem("ressources", JSON.stringify(data.json));
    }
  });

  function ressourceChange(event: CustomEvent<number>) {
    const newIndex = event.detail;
    ressourceIndex = newIndex;
    // change route (if needed) (ressourceId is the first arg)
    if ($page.params.ressourceId) {
      let newUrl = `${$ressources[newIndex].id}`;
      const oldUrl = $page.url.pathname.split("/");
      console.log(oldUrl);

      if (oldUrl.length > 2) {
        newUrl = `/${$ressources[newIndex].id}/${oldUrl[2]}`;
      }
      goto(newUrl);
      ressourceIdStore.set($ressources[newIndex].id);
    }
    // goto(`/activities/${$ressources[newIndex].id}`);
  }
</script>

<title>{title}</title>

<div class="app">
  {#if printer}
    <div class="print">
      <slot></slot>
    </div>
  {:else}
    <div class="bg-gray-100 dark:bg-gray-900">
      <Header ressourceId={ressourceSelectedId.toString()} />
      <div class="view h-full w-full">
        <div class="flex">
          <!-- 240px if laptop , mobile 64px, whichever is greater -->
          <div class="xl:w-48 lg:w-48 md:w-48 sm:w-16 w-16 h-full">
            <Sidebar
              ressources={$ressources}
              on:ressourceChange={ressourceChange}
            />
          </div>
          <main class="p-4 w-full">
            <slot></slot>
          </main>
        </div>
      </div>
      <div>
        <div class="notification">
          {#each toasts as toast, index}
            {#if toast.type === "success"}
              <Toast
                color="green"
                show={toast.show}
                style="transition: all 0.5s ease; transform: translateY({index *
                  10}%)"
                on:close={() => notificationStore.remove(toast.id)}
              >
                <svelte:fragment slot="icon">
                  <CheckCircleSolid class="w-5 h-5" />
                  <span class="sr-only">Check icon</span>
                </svelte:fragment>
                <!-- <span>{toast.message}</span> -->
                {@html toast.message}
              </Toast>
            {:else if toast.type === "error"}
              <Toast
                color="red"
                show={toast.show}
                style="transition: all 0.5s ease; transform: translateY({index *
                  10}%)"
                on:close={() => notificationStore.remove(toast.id)}
              >
                <svelte:fragment slot="icon">
                  <ExclamationCircleSolid class="w-5 h-5" />
                  <span class="sr-only">Warning icon</span>
                </svelte:fragment>
                <!-- <span>{toast.message}</span> -->
                {@html toast.message}
              </Toast>
            {/if}
          {/each}
        </div>
      </div>
    </div>

    <footer class="bg-gray-50 py-6 border-t dark:bg-gray-800">
      <div
        class="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center"
      >
        <div class="mb-4 md:mb-0">
          <p class="text-sm text-gray-500">
            &copy; 2024 BBIA. All rights reserved.
          </p>
        </div>
        <div class="flex space-x-6">
          <a href="/about" class="text-sm text-gray-500 hover:text-gray-700">
            About Us
          </a>
          <a href="/contact" class="text-sm text-gray-500 hover:text-gray-700">
            Contact
          </a>
          <a href="/privacy" class="text-sm text-gray-500 hover:text-gray-700">
            Privacy Policy
          </a>
          <a href="/terms" class="text-sm text-gray-500 hover:text-gray-700">
            Terms of Service
          </a>
        </div>
        <div class="flex space-x-4 mt-4 md:mt-0">
          <a
            href="https://www.linkedin.com/company/bbia"
            class="text-gray-500 hover:text-gray-700"
            aria-label="LinkedIn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                d="M16 8a6 6 0 00-6-6 6 6 0 00-6 6v9h4v-9a2 2 0 114 0v9h4V8zM4 0h4v4H4V0zm2 16H2v-6h4v6z"
              />
            </svg>
          </a>
          <a
            href="https://twitter.com/BBIA"
            class="text-gray-500 hover:text-gray-700"
            aria-label="Twitter"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                d="M24 4.56a9.83 9.83 0 01-2.82.775 4.93 4.93 0 002.165-2.725 9.9 9.9 0 01-3.127 1.195A4.92 4.92 0 0016.616 3c-2.737 0-4.95 2.238-4.95 5s.1 1 .375 2.06a14.04 14.04 0 01-10.187-5.165 4.93 4.93 0 001.52 6.6 4.899 4.899 0 01-2.228-.617v.06a4.916 4.916 0 003.946 4.826 4.91 4.91 0 01-2.224.086A4.935 4.935 0 005.919 19c-3.03.03-5.867-2.394-6.626-5.42a13.951 13.951 0 007.548 2.22C15.62 15.8 20 9.79 20 5.257v-.568A9.71 9.71 0 0022 3.56a9.858 9.858 0 01-2.85.78z"
              />
            </svg>
          </a>
          <a
            href="https://facebook.com/BBIA"
            class="text-gray-500 hover:text-gray-700"
            aria-label="Facebook"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                d="M9 8h2V6c0-.71.192-1.361.521-1.897.295-.486.727-.829 1.24-1.018C13.598 2.66 14.8 2.96 15 4H16v3h-2c-1.1 0-1.8.9-1.8 2v1h3.22l-.72 4H12v9H8V13H5v-4h3V8z"
              />
            </svg>
          </a>
        </div>
      </div>
    </footer>
  {/if}
</div>

<style>
  .header {
    min-height: 80px;
  }
  .app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .notification {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 9999;
  }

  main {
    min-height: calc(100vh - 80px - 60px);
  }

  footer {
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: self-start;
    padding: 12px;
    min-height: 60px;
  }

  @media (min-width: 480px) {
    footer {
      padding: 12px 0;
    }
  }
</style>
